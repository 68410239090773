import { Box, Button, Fade, Slide, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

const ProjectsItem = (props) => {
    const [itemFade, setItemFade] = React.useState(false);

    useEffect(() => {
        setItemFade(true)
    }, [])

    return (
        <Fade in={itemFade} timeout={750} style={{ transitionDelay: itemFade ? "100ms" : '0ms' }}>
            <Box>
                {
                    window.innerWidth > global.MIN_SCREEN_SIZE ?
                        (
                            <Stack sx={{ ":hover": { boxShadow: 5 } }} direction={"column"} border={1} borderRadius={"25px"} padding={2} spacing={1} alignItems={"center"} className="skills clickable">
                                <img height={props.imageHeight} width={props.imageWidth} src={props.image} />
                                <Typography variant="h4">{props.name}</Typography>
                            </Stack>
                        ) :
                        (
                            <Stack sx={{ ":hover": { boxShadow: 5 } }} direction={"row"} border={1} borderRadius={"25px"} padding={2} spacing={1} className="clickable">
                                <img height={props.imageHeight} width={props.imageWidth} src={props.image} />
                                <Typography variant="h4">{props.name}</Typography>
                            </Stack>
                        )
                }
            </Box>
        </Fade>
    )
}

export default ProjectsItem;
