import { Box, List, ListItem, ListItemText, Slide, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {Link} from "react-router-dom";

const Experience = () => {
    const [slide, setSlide] = React.useState(false);

    const description = "Working for Create Matter Building back office and main product line applications using a variety of languages, libraries, and services."
    const bullet1 = " - Website analyzing political donations using FEC data processed through a back-office tool to a MySQL database. SQL queries are processed against the database to create JSON files. Those files are then uploaded to Amazon S3 to hold and deliver them through a REST API. Created with the CD/CI model in thought. Site changes can be pushed and seen immediately."
    const bullet2 = "SiteManager - Back-end tool to manage the home page of the Monecracy website. Intuitive GUI interface to add sections or change details of the home page. A java data object is serialized and saved into a json file that is uploaded to AWS S3 and used to build the home page."
    const bullet3 = "Broadtask - Scalable self-configurable bot net to distribute tasks to a global network."
    const bullet4 = "Taskable Pi - Multithreaded python GUI application to connect to the broadtask network, complete tasks, and upload results."
    const bullet5 = "ListCloud - Software to make loading data arrays easier. GUI interface that provides lists that can be loaded into other software components."

    useEffect(() => {
        setSlide(true)
    }, [])

    return (
        <Stack paddingTop={5} paddingBottom={5}>
            {
                window.innerWidth > global.MIN_SCREEN_SIZE ?
                    (
                        <Slide timeout={800} direction={"left"} in={slide} mountOnEnter unmountOnExit>
                            <Stack alignItems={"center"}>
                                <Stack direction={"column"} width={"50%"} padding={2} alignItems={"center"}>
                                    <Typography color={global.THIRD_TEXT_COLOR} variant="h3" paddingBottom={5}>Recent Expereince</Typography>
                                    <Typography color={global.THIRD_TEXT_COLOR} textAlign={"left"} variant="h5">{description}</Typography>
                                    <Stack paddingLeft={2} color={global.THIRD_TEXT_COLOR}>
                                        <List>
                                            {/*<ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>*/}
                                            {/*    <Typography variant="h6">{bullet1}</Typography>*/}
                                            {/*</ListItem>*/}
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography variant="h6"><Link to={"https://www.monecracy.com"}>Monecracy.com</Link>{bullet1}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography variant="h6">{bullet2}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography variant="h6">{bullet3}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography variant="h6">{bullet4}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography variant="h6">{bullet5}</Typography>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Slide>

                    ) :
                    (
                        <Slide timeout={800} direction={"up"} in={slide} mountOnEnter unmountOnExit>
                            <Stack alignItems={"center"}>
                                <Stack direction={"column"} padding={2} spacing={2} alignItems={"center"}>
                                    <Typography color={global.THIRD_TEXT_COLOR} textAlign={"left"} variant="h5">Recent Expereince</Typography>
                                    <Typography color={global.THIRD_TEXT_COLOR} textAlign={"left"} variant="subtitle1">{description}</Typography>
                                    <Box paddingLeft={2}>
                                        <List>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography color={global.THIRD_TEXT_COLOR}><Link to={"https://www.monecracy.com"}>Monecracy.com</Link>{bullet1}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography color={global.THIRD_TEXT_COLOR} variant="body1">{bullet2}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography color={global.THIRD_TEXT_COLOR} variant="body1">{bullet3}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography color={global.THIRD_TEXT_COLOR} variant="body1">{bullet4}</Typography>
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }}>
                                                <Typography color={global.THIRD_TEXT_COLOR} variant="body1">{bullet5}</Typography>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Slide>
                    )
            }
        </Stack>
    )
};

export default Experience;
