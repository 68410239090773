import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import NavBar from "../../components/NavBar";
import intelijPic from "../../resources/images/projects/projectThisWebsite/intelij-screenshot.png"
import javaAppPic from "../../resources/images/projects/projectThisWebsite/java-app-screenshot.png"
import amplifyPic from "../../resources/images/projects/projectThisWebsite/amplify-screenshot.png"
import bucketPic from "../../resources/images/projects/projectThisWebsite/bucket-screenshot.png"
import webstormPic from "../../resources/images/projects/projectThisWebsite/webstorm-screenshot.png"
import tortisePic from "../../resources/images/projects/projectThisWebsite/tortoise-git-screenshot.png"
import ScrollToTop from "../../components/ScrollToTop";

const ProjectThisWebsite = () => {
    return (
        <Stack direction={"column"}>
            <Box id="back-to-top-anchor"></Box>
            <NavBar />
            {
                window.innerWidth > global.MIN_SCREEN_SIZE ?
                    (
                        <Stack direction={"column"} alignItems={"center"} spacing={5} paddingTop={5} marginTop={10}>
                            <Stack width={window.innerWidth * .50} spacing={2}>
                                <Typography variant={"h3"}>This Website!</Typography>
                                <Typography variant="h5">
                                    This website is created using React JS. The code is hosted on AWS Cloud Services
                                    using Amplify to build from a connected github repository. This pattern is
                                    consistent with the current industry standard CI/CD (Continuous Integration / Continuous Deployment). To make coding and building a little easier I
                                    use Webstorm for React JS, React Native, HTML, and CSS. InteliJ is used to handle Java and JavaFX.
                                </Typography>
                                <Typography variant="h5">
                                    The data of the website is pulled down from a JSON file hosted in a AWS S3 bucket.
                                    This JSON file is created using a Java and JavaFX GUI application to represent
                                    different sections of the website. Once I make changes within my JavaFX application
                                    and save them, a java object is serialized into JSON and pushed to the cloud. This means the data is updated
                                    instantly and the changes visible immediately. I think this is a good display of my understanding of not only the
                                    backend of applications but the front as well, showing my understanding of the full
                                    stack.
                                </Typography>
                            </Stack>

                            <Stack alignItems={"center"} width={window.innerWidth} padding={2}>
                                <Typography variant={"h6"} width={window.innerWidth * .5}>
                                    This is what the javaFX application looks like. Once the save button is pressed, the website data will be published and live. The highlight of this GUI is the skills section. Here custom Item Renders are used to handle the onSelect and onDelete aspects of operation.
                                </Typography>
                                <img src={javaAppPic} width={"50%"}/>
                            </Stack>

                            <Stack alignItems={"center"} sx={{bgcolor: "gray"}} width={window.innerWidth} padding={2}>
                                <Typography variant={"h6"} width={window.innerWidth * .5}>
                                    This is a screen shot of InteliJ showcasing the FXML code behind the above GUI application. Also showcased here is my project structure. The entire app is started from the Launcher class.
                                </Typography>
                                <img src={intelijPic} width={"50%"}/>
                            </Stack>

                            <Stack alignItems={"center"} width={window.innerWidth} padding={2}>
                                <Typography variant={"h6"} width={window.innerWidth * .5}>
                                    The screenshot below shows the app hosted in AWS Amplify that is synced up to a github repository. As soon as a new commit is detected, the site is rebuilt and redeployed demonstrating a CD/CI model.
                                </Typography>
                                <img src={amplifyPic} width={"50%"}/>
                            </Stack>

                            <Stack alignItems={"center"} sx={{bgcolor: "gray"}} width={window.innerWidth} padding={2}>
                                <Typography variant={"h6"} width={window.innerWidth * .5}>
                                    A screenshot of the home JSON file stored in an AWS S3 bucket and pushed from my javaFX application. The home file is used to load data on this website, like my name anywhere you see it. All updated in one place.
                                </Typography>
                                <img src={bucketPic} width={"50%"}/>
                            </Stack>

                            <Stack alignItems={"center"} width={window.innerWidth} padding={2}>
                                <Typography variant={"h6"} width={window.innerWidth * .5}>
                                    The picture below shows my Webstorm environment and project structure. The file being showcased is the HomePage component. It consists of other smaller components all put together to form the complete page.
                                </Typography>
                                <img src={webstormPic} width={"50%"}/>
                            </Stack>

                            <Stack alignItems={"center"} sx={{bgcolor: "gray"}} width={window.innerWidth} padding={2}>
                                <Typography variant={"h6"} width={window.innerWidth * .5}>
                                    The github management for the whole project is done using tortoise git. This is a screen shot of the log for this repository.
                                </Typography>
                                <img src={tortisePic} width={"50%"}/>
                            </Stack>


                            <Stack position={"absolute"} bottom={0} width={"100%"}>
                                {/*<Footer />*/}
                            </Stack>
                        </Stack>
                    ) :
                    (
                        <Stack direction={"column"} marginTop={10}>
                            <Stack direction={"column"} alignItems={"center"} spacing={5} paddingTop={5}>
                                <Stack width={window.innerWidth * .85} spacing={2}>
                                    <Typography variant={"h4"}>This Website!</Typography>
                                    <Typography variant="subtitle1">
                                        This website is created using React JS. The code is hosted on AWS Cloud Services
                                        using Amplify to build from a connected github repository. This pattern is
                                        consistent with the current industry standard CI/CD (Continuous Integration / Continuous Deployment). To make coding and building a little easier I
                                        use Webstorm for React JS, React Native, HTML, and CSS. InteliJ is used to handle Java and JavaFX.
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        The data of the website is pulled down from a JSON file hosted in a AWS S3 bucket.
                                        This JSON file is created using a Java and JavaFX GUI application to represent
                                        different sections of the website. Once I make changes within my JavaFX application
                                        and save them, a java object is serialized into JSON and pushed to the cloud. This means the data is updated
                                        instantly and the changes visible immediately. I think this is a good display of my understanding of not only the
                                        backend of applications but the front as well, showing my understanding of the full
                                        stack.
                                    </Typography>
                                </Stack>

                                <Stack alignItems={"center"} width={window.innerWidth}>
                                    <Typography variant={"subtitle1"} width={window.innerWidth * .85} paddingTop={2} paddingBottom={2}>
                                        This is what the javaFX application looks like. Once the save button is pressed, the website data will be published and live. The highlight of this GUI is the skills section. Here custom Item Renders are used to handle the onSelect and onDelete aspects of operation.
                                    </Typography>
                                    <img src={javaAppPic} width={"95%"} />
                                </Stack>

                                <Stack alignItems={"center"} sx={{bgcolor: "gray"}} width={window.innerWidth}>
                                    <Typography variant={"subtitle1"} width={window.innerWidth * .85} paddingTop={2} paddingBottom={2}>
                                        This is a screen shot of InteliJ showcasing the FXML code behind the above GUI application. Also showcased here is my project structure. The entire app is started from the Launcher class.
                                    </Typography>
                                    <img src={intelijPic} width={"95%"}/>
                                </Stack>

                                <Stack alignItems={"center"} width={window.innerWidth}>
                                    <Typography variant={"subtitle1"} width={window.innerWidth * .85} paddingTop={2} paddingBottom={2}>
                                        The screenshot below shows the app hosted in AWS Amplify that is synced up to a github repository. As soon as a new commit is detected, the site is rebuilt and redeployed demonstrating a CD/CI model.
                                    </Typography>
                                    <img src={amplifyPic} width={"95%"}/>
                                </Stack>

                                <Stack alignItems={"center"} sx={{bgcolor: "gray"}} width={window.innerWidth}>
                                    <Typography variant={"subtitle1"} width={window.innerWidth * .85} paddingTop={2} paddingBottom={2}>
                                        A screenshot of the home JSON file stored in an AWS S3 bucket and pushed from my javaFX application. The home file is used to load data on this website, like my name anywhere you see it. All updated in one place.
                                    </Typography>
                                    <img src={bucketPic} width={"95%"}/>
                                </Stack>

                                <Stack alignItems={"center"} width={window.innerWidth}>
                                    <Typography variant={"subtitle1"} width={window.innerWidth * .85} paddingTop={2} paddingBottom={2}>
                                        The picture below shows my Webstorm environment and project structure. The file being showcased is the HomePage component. It consists of other smaller components all put together to form the complete page.
                                    </Typography>
                                    <img src={webstormPic} width={"95%"}/>
                                </Stack>

                                <Stack alignItems={"center"} sx={{bgcolor: "gray"}} width={window.innerWidth}>
                                    <Typography variant={"subtitle1"} width={window.innerWidth * .85} paddingTop={2} paddingBottom={2}>
                                        The github management for the whole project is done using tortoise git. This is a screen shot of the log for this repository.
                                    </Typography>
                                    <img src={tortisePic} width={"95%"}/>
                                </Stack>


                                <Stack position={"absolute"} bottom={0} width={"100%"}>
                                    {/*<Footer />*/}
                                </Stack>
                            </Stack>
                        </Stack>
                    )
            }
            <ScrollToTop />
        </Stack>
    )
}

export default ProjectThisWebsite
