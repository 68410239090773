import {Box, Button, Slide, Stack} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProjectsButton = (props) => {
    const navigate = useNavigate();
    const handleNav = (path) => {
        console.log("CLICK")
        navigate(path)
    }

    return (
        <Stack alignItems={"center"}>
            <Slide timeout={props.slideDelay} direction={props.slideDirection} in={props.slide} mountOnEnter unmountOnExit>
                <Box>
                    <Button onClick={event => handleNav(props.path)} variant="outlined" style={{ backgroundColor: global.BUTTON_COLOR, color: global.BUTTON_TEXT_COLOR }}>{props.name}</Button>
                </Box>
            </Slide>
        </Stack>
    )
}

export default ProjectsButton;
