import { Box, Fade, PropTypes, useScrollTrigger, Zoom } from "@mui/material";
import arrow from '../resources/images/back-to-top-icon.png'
import React from "react";

const ScrollToTop = (props) => {
    const { children } = props;

    const trigger = useScrollTrigger({
        // target: window ? window() : undefined,
        // disableHysteresis: true,
        // threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: "smooth"
            });
        }
    };

    return (
        <Fade in={trigger}>
            {
                window.innerWidth > global.MIN_SCREEN_SIZE ?
                    (
                        <Box
                            onClick={handleClick}
                            role="presentation"
                            sx={{ position: 'fixed', bottom: 10, right: 16 }}>
                            {children}
                            <img className={"clickable"} height={75} width={75} src={arrow} />
                        </Box>
                    ) :
                    (
                        <Box
                            onClick={handleClick}
                            role="presentation"
                            sx={{ position: 'fixed', bottom: 10, right: 16 }}>
                            {children}
                            <img className={"clickable"} height={60} width={60} src={arrow} />
                        </Box>
                    )
            }
        </Fade>
    );
}

export default ScrollToTop
