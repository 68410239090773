import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonGroup, Slide, Stack, Typography } from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";

const NavBar = (props) => {
    const [success, setSuccess] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [homeSelected, setHomeSelected] = useState(false);
    const [projectsSelected, setProjectsSelected] = useState(false);
    const [slide, setSlide] = React.useState(false);

    const navigate = useNavigate();

    const selectedColor = "#C5D86D"

    const handleSelected = (path) => {
        navigate(path)
    }

    function loadData () {
        const loadURL = "https://rthjq56g67.execute-api.us-west-1.amazonaws.com/v1/home"
        axios.get(loadURL)
            .then((res) => {
                    if (res.data === undefined || res.data === null)
                    {
                        setSuccess(false);
                        setData([])
                    }
                    else
                    {
                        setSuccess(true);
                        setData(res.data);
                    }
                },
            );
    }

    useEffect(() => {
        setSlide(true)

        if (props.selectedType === "home") {
            setHomeSelected(true)
        }
        if (props.selectedType === "projects") {
            setProjectsSelected(true)
        }

        loadData()

    }, [])

    return (
        // <Slide timeout={800} direction={"down"} in={slide} mountOnEnter unmountOnExit>
        <Stack zIndex={1} position={"fixed"} width={"100%"}>
        {/*<Stack>*/}
            {
                window.innerWidth > global.MIN_SCREEN_SIZE ?
                    (
                        <Stack direction={"row"} justifyContent={"space-between"} spacing={2} padding={2} paddingRight={20} bgcolor={global.BACKGROUND_COLOR} height={50}>
                            {
                                homeSelected === false ?
                                    (
                                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                            <Typography variant='h4' color={global.SECOND_TEXT_COLOR}><Link style={{textDecoration: "none", boxShadow: "none", color: "inherit"}} to={"/"}>{data.name}</Link></Typography>
                                            <Typography variant='subtitle2' color={global.SECOND_TEXT_COLOR}>{data.title}</Typography>
                                        </Stack>
                                    ) :
                                    (<Typography></Typography>)
                            }
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Button variant="outlined" onClick={event => handleSelected("/")} style={{ height:40, backgroundColor: homeSelected ? selectedColor : global.BUTTON_COLOR, color: homeSelected ? "black" : global.TEXT_COLOR }}>Home</Button>
                                <Button variant="outlined" onClick={event => handleSelected("/projects")} style={{ height:40, backgroundColor: projectsSelected ? selectedColor : global.BUTTON_COLOR, color: projectsSelected ? "black" : global.TEXT_COLOR }}>Projects</Button>
                            </Stack>
                        </Stack>
                    ) :
                    (
                        <Stack direction={"row"} justifyContent={"space-between"} spacing={2} padding={2} bgcolor={global.BACKGROUND_COLOR} height={50}>
                            {
                                homeSelected === false ?
                                    (
                                        <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                            <Typography variant='subtitle2' color={global.SECOND_TEXT_COLOR}><Link style={{textDecoration: "none", boxShadow: "none", color: "inherit"}} to={"/"}>{data.name}</Link></Typography>
                                        </Stack>
                                    ) :
                                    (<Typography></Typography>)
                            }
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <Button variant="outlined" onClick={event => handleSelected("/")} style={{ height:40, backgroundColor: homeSelected ? selectedColor : global.BUTTON_COLOR, color: homeSelected ? "black" : global.TEXT_COLOR }}>Home</Button>
                                <Button variant="outlined" onClick={event => handleSelected("/projects")} style={{ height:40, backgroundColor: projectsSelected ? selectedColor : global.BUTTON_COLOR, color: projectsSelected ? "black" : global.TEXT_COLOR }}>Projects</Button>
                            </Stack>
                        </Stack>
                    )
            }
        </Stack>
        // </Slide>/
    )
};

export default NavBar;
