import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import NavBar from "../components/NavBar";
import {useEffect} from "react";
import axios from "axios"
import About from "../components/About";
import Skills from "../components/skills/Skills";
import Experience from "../components/Experience";
import Projects from "../components/projects/Projects";
import ScrollToTop from "../components/ScrollToTop";

const HomePage = () => {
    const [success, setSuccess] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [skills, setSkills] = React.useState([]);

    function loadData () {
        const loadURL = "https://rthjq56g67.execute-api.us-west-1.amazonaws.com/v1/home"
        axios.get(loadURL)
            .then((res) => {
                    if (res.data === undefined || res.data === null)
                    {
                        setSuccess(false);
                        setData([])
                    }
                    else
                    {
                        setSuccess(true);
                        setData(res.data);
                        setSkills(res.data.skills)
                    }
                },
            );
    }

    useEffect(() => {
        loadData()
    }, []);

    return (
        <Stack>
            <Box id="back-to-top-anchor"></Box>
            <NavBar selectedType={"home"} />
            <Stack direction={"column"} marginTop={10}>
                <About name={data.name} title={data.title} description={data.description} />
                <Skills skills={skills} />
                <Projects />
                <Experience />
            </Stack>
            <ScrollToTop />
        </Stack>
    )
};

export default HomePage;
