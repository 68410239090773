import { Box, ButtonBase, Grid, Stack, Typography } from "@mui/material";
import React from "react";

import placeHolderImage from "../resources/images/place_holder_icon.png"
import projectIcon from "../resources/images/project.png"
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import ProjectsItem from "../components/projects/ProjectsItem";

const ProjectsPage = () => {

    const navigate = useNavigate();
    const handleSelected = (path) => {
        console.log("CLICK")
        navigate(path)
    }

    return (
        <Stack height={"100vh"} met>
            <NavBar selectedType="projects" />
            {
                window.innerWidth > global.MIN_SCREEN_SIZE ?
                    (
                        <Box flexGrow={"revert"} marginTop={10}>
                            <Grid container direction={"row"} padding={4} spacing={2}>
                                <Grid item xs={3}>
                                    <Box onClick={event => handleSelected("/projectThisWebsite")}>
                                        <ProjectsItem name="This Website!" image={projectIcon} imageHeight="100" imageWidth="100" />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Stack position={"absolute"} bottom={0} width={"100%"}>
                                {/*<Footer />*/}
                            </Stack>
                        </Box>
                    ) :
                    (
                        <Box marginTop={10}>
                            <Grid container direction={"column"} padding={2} spacing={2}>
                                <Grid item xs={1}>
                                    <Box onClick={event => handleSelected("/projectThisWebsite")}>
                                        <ProjectsItem name="This Website" image={projectIcon} imageHeight="100" imageWidth="100" />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Stack position={"bottom"} bottom={0} width={"100%"}>
                                {/*<Footer />*/}
                            </Stack>
                        </Box>
                    )
            }
        </Stack>
    )
};

export default ProjectsPage;
