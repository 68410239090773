import {Stack} from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import "./global"
import ProjectsPage from "./pages/ProjectsPage";
import ProjectThisWebsite from "./pages/projects/ProjectThisWebsite";

function AppMain() {
    return(
        <Stack>
            <Router>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="projects" element={<ProjectsPage />} />
                    <Route path="projectThisWebsite" element={<ProjectThisWebsite />} />
                </Routes>
            </Router>
        </Stack>
    );
}

export default AppMain;
