import React, {useEffect} from "react";
import {Divider, Fade, Stack, Typography} from "@mui/material";
import Skill from "./Skill";

const LoadedSkills = (props) => {
    const [titleFade, setTitleFade] = React.useState(true);
    const [directionIRs, setDirectionIRs] = React.useState("row");
    const [imageSize, setImageSize] = React.useState()

    function buildHomePageCards()
    {
        const cards = [];

        props.skills.forEach(function (skill) {
            cards.push(
                <Stack>
                    <Skill skill={skill.key} text={skill.description} image={skill.imageURL} fade={true} fadeDelay="700ms" slide={true} slideDirection="right" slideDelay={600} open={false} imageHeight={imageSize} imageWidth={imageSize} bgColor="black" />
                </Stack>
            )
        });

        return cards
    }

    useEffect(() => {
        window.innerWidth > global.MIN_SCREEN_SIZE ? setDirectionIRs("row"):(setDirectionIRs("column"))
        window.innerWidth > global.MIN_SCREEN_SIZE ? setImageSize(150):(setImageSize(100))
    }, []);

    return(
        <Stack>
            <Stack padding={10} spacing={5} alignItems={"center"}>
                <Fade in={titleFade} style={{ transitionDelay: titleFade ? global.FADE_DELAY : '0ms' }}>
                    <Typography variant="h3">Skills</Typography>
                </Fade>
                <Stack spacing={5} direction={directionIRs} justifyContent={"center"} flexWrap={"wrap"} maxWidth={window.innerWidth * .75}>
                    {
                        buildHomePageCards()
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}

export default LoadedSkills
