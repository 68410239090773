import React, { useEffect } from "react"
import { Box, Slide, Stack, Typography } from "@mui/material"
import profilePicture from "../resources/images/me.png"

const About = (props) => {

    const [slide, setSlide] = React.useState(false);

    useEffect(() => {
        setSlide(true)
    }, [])

    return (
        <Slide timeout={800} direction={"down"} in={slide} mountOnEnter unmountOnExit>
            <Box>
                <Stack direction={"column"} bgcolor={global.BACKGROUND_COLOR} color={global.TEXT_COLOR}>
                    {
                        window.innerWidth > global.MIN_SCREEN_SIZE ?
                            (
                                <Box>
                                    <Stack direction={"column"} textAlign={"left"} padding={2}>
                                        <Typography color={global.SECOND_TEXT_COLOR} variant="h3">{props.name}</Typography>
                                        <Typography color={global.SECOND_TEXT_COLOR} variant="h6">{props.title}</Typography>
                                    </Stack>
                                    <Stack direction={"row"} justifyContent={"center"} spacing={2} paddingBottom={10}>
                                        <img src={profilePicture} height={400} width={300} />
                                        <Typography variant="h5" width={"30%"} textAlign={"left"}>{props.description}</Typography>
                                    </Stack>
                                </Box>
                            ) :
                            (
                                <Box>
                                    <Stack direction={"column"} justifyContent={"center"} spacing={2} padding={2} paddingBottom={4} paddingTop={4}>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <img src={profilePicture} height={300} width={200} />
                                        </Stack>
                                        <Stack direction={"column"} textAlign={"left"}>
                                            <Typography color={global.SECOND_TEXT_COLOR} variant="h6">{props.name}</Typography>
                                            <Typography color={global.SECOND_TEXT_COLOR} variant="subtitle1">{props.title}</Typography>
                                        </Stack>
                                        <Typography textAlign={"left"}>{props.description}</Typography>
                                    </Stack>
                                </Box>
                            )
                    }
                </Stack>
            </Box>
        </Slide>
    )
}

export default About
