import { Box, Grid, Slide, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ProjectsButton from "./ProjectButton";

const Projects = () => {
    const [slide, setSlide] = React.useState(true)

    const [button1Slide, setButton1Slide] = React.useState(false)
    const [button2Slide, setButton2Slide] = React.useState(false)

    useEffect(() => {
        // setSlide(true)
        setButton1Slide(true)
        setButton2Slide(true)
    }, [])

    return (
        <Slide timeout={800} direction={"up"} in={slide} mountOnEnter unmountOnExit>
            <Stack>
                {
                    window.innerWidth > global.MIN_SCREEN_SIZE ?
                        (
                            <Slide timeout={800} direction={"up"} in={slide} mountOnEnter unmountOnExit>
                                <Stack bgcolor={global.BACKGROUND_COLOR} alignItems={"center"}>
                                    <Stack direction={"column"} width={"50%"} padding={5} paddingTop={10} paddingBottom={10} alignItems={"center"}>
                                        <Typography variant="h3" color={"white"} paddingBottom={5}>Projects</Typography>
                                        <Grid container spacing={4} justifyContent={"center"}>
                                            <Grid item xs={6} alignItems={"center"}>
                                                <ProjectsButton name="This Website" path="projectThisWebsite" slide={button1Slide} slideDirection="right" slideDelay={600} />
                                            </Grid>

                                            {/*<Grid item xs={6}>*/}
                                            {/*    <ProjectsButton name="Test Project" path="project1" slide={button2Slide} slideDirection="left" slideDelay={600} />*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </Slide>
                        ) :
                        (
                            <Slide timeout={800} direction={"up"} in={slide} mountOnEnter unmountOnExit>
                                <Stack bgcolor={global.BACKGROUND_COLOR}>
                                    <Stack direction={"column"} padding={5} paddingTop={10} paddingBottom={10} alignItems={"center"}>
                                        <Typography variant="h3" color={"white"} paddingBottom={5}>Projects</Typography>
                                        <Grid container spacing={4} justifyContent={"center"}>
                                            <Grid item xs={12}>
                                                <ProjectsButton name="Test Project" path="projectThisWebsite" slide={button1Slide} slideDirection="right" slideDelay={600} />
                                            </Grid>

                                            {/*<Grid item xs={12}>*/}
                                            {/*    <ProjectsButton name="Test Project" path="project1" slide={button2Slide} slideDirection="left" slideDelay={600} />*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                    </Stack>
                                </Stack>
                            </Slide>
                        )
                }
            </Stack>
        </Slide>
    )
}

export default Projects;
